import {Grid, makeStyles, Typography} from "@material-ui/core";
import backGround from "../media/background.png";
import aboutUs2 from "../media/aboutUs2.png";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundImage: `url(${backGround})`,

    },
    AboutUsImage: {
        objectFit: "cover",
        width: "100%",
        height: "auto",

    },
    secondWords: {
        marginTop: "70px",
        marginBottom: "70px",
        fontFamily: 'Ubuntu',
        fontSize: "2.125rem",
        ['@media (max-Width: 550px)']: {
            marginTop: "20px",
            marginBottom: "20px",
            fontSize: "1rem"
        }
    },
    firstWords: {
        marginTop: "30px",
        marginBottom: "30px",
        fontFamily: 'Ubuntu',
        fontSize: "2.125rem",
        ['@media (max-Width: 550px)']: {
            fontSize: "1rem",
        }
    },
    text: {
        position: "absolute",
        padding: "20px",
        top: "92%",
        left: "50%",
        borderRadius: "70px",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#2f3030",
        fontSize: "1.2vw",
        fontFamily: "Ubuntu",
        ['@media (max-Width: 550px)']: {
            display: "none"
        }
    },
    container: {
        color: "white",
        position: "relative",
        fontFamily: 'Ubuntu',

    }
}))

export const AboutUsSecond = () => {
    const styles = useStyles()
    return (
        <Grid container direction={"column"} className={styles.AboutUs}>
            <Grid item xs>
                <Typography align={"center"} className={styles.firstWords}>
                    <strong>
                        НАМИ ВІДРОДЖЕНО <br/>
                        ЗАБУТІ, КЛАСИЧНІ РЕЦЕПТИ <br/>
                        ВИГОТОВЛЕННЯ КОВБАСНИХ ВИРОБІВ
                    </strong>
                </Typography>
            </Grid>
            <Grid item xs className={styles.container}>
                <img className={styles.AboutUsImage} src={aboutUs2} alt={"mountains"}/>
                <Typography align={"center"} variant={"h5"} className={styles.text}>
                    <strong>
                        НАМ ВДАЛОСЯ СТВОРИТИ НЕПЕРЕВЕРШЕНИЙ СМАК,
                        ОБ'ЄДНАВШИ ТРАДИЦІЙНІ ТА НОВІТНІ ТЕХНОЛОГІЇ.
                    </strong>
                </Typography>
            </Grid>
            <Grid container direction={"column"} alignContent={"center"}>
              <Typography align={"center"}  id={"productionCatalog"} className={styles.secondWords}>
                  <strong>
                      НАША ПРОДУКЦІЯ СМАЧНА, АПЕТИТНА <br/>
                        І ЦІЛКОМ БЕЗПЕЧНА ДЛЯ СПОЖИВАННЯ<br/>
                  </strong>
                </Typography>
            </Grid>
        </Grid>
    )
}