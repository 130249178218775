import {NavLink} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid, Menu, MenuItem, Typography} from "@material-ui/core";
import React from "react";
import {Scrollchor} from 'react-scrollchor';
import {NavItem} from "@mui-treasury/components/menu/navigation";
import {HashLink as Link} from 'react-router-hash-link';

const useStyles = makeStyles((theme) => ({
    Main: {
        backgroundColor: "#2f3030",
        position: "sticky",
        top: 0,
        zIndex: "1",
        "& a": {
            color: "white",
            textDecoration: "none",
        },
        "& a:hover": {
            color: "white",
            textDecoration: "none",
        },
        ['@media (max-Width: 666px)']: {
            position: "relative",
            top: 0,
            zIndex: "1",
        }
    },
    other: {
        color: "black",
        textDecoration: "none",
        "&:hover": {
            color: "black",
            textDecoration: "none",


        }
    },
    link: {
        margin: "5px 8px 5px 8px",
        fontFamily: 'Ubuntu',
        fontStyle: "normal",
    }

}))

export const Header = (props) => {
    const styles = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Grid container direction={"row"} alignItems={"center"} justify={"center"} className={styles.Main}>
            <Link to={"/#main"} className={styles.link}><strong>ГОЛОВНА</strong></Link>
            <Link to={"/#aboutUs"} className={styles.link}><strong>ПРО НАС</strong></Link>
            <Link to={"/#productionCatalog"} className={styles.link}><strong>КАТАЛОГ ПРОДУКЦІЇ</strong></Link>
            <Link to={"/#whyWe"} className={styles.link}><strong>ЧОМУ НАША ПРОДУКЦІЯ</strong></Link>
            <Link to={"/#ourAchivements"} className={styles.link}><strong>НАШІ ДОСЯГНЕННЯ</strong></Link>
            <Link to={"/#contacts"} className={styles.link}><strong>КОНТАКТИ</strong></Link>
        </Grid>
    )
}