import MainPage from "./components/MainPage";
import { ProductsPage} from "./components/ProductPageNew/ProductsPage";
import {Routes, Route, Navigate, Redirect} from 'react-router-dom';
import {categories} from "./State/State";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
      <>
        <Routes>
          <Route  path={'/'} element={<MainPage/>}/>
            {categories.map(item => <Route exact path={`/${item.nameTag}`} element={<ProductsPage data={item}/>}/>)}
        </Routes>
      </>
  );
}

export default App;
