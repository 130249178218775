import React from 'react';
import {makeStyles,} from "@material-ui/core";
import {Carousel} from "react-bootstrap";

import firstSlide from "../media/slide 1.png"
import secondSlide from "../media/slide 2.png"
import thirdSlide from "../media/slide 3.png"
import fourthSlide from "../media/slide 4.png"
import kelbasa from "../media/kelbasa.png"

const useStyles = makeStyles({
    slider: {
        objectFit: "cover",
        width: "100%",
        height: "auto",
        maxHeight: "800px"

    },
    logo: {
        shape: "circle",
        position: "absolute",
        width: "315px",   /*задаем размеры кликабельной области*/
        height:"200px",
        cursor: "pointer", /*меняем рисунок курсора, чтобы показать
кликабельность объекта*/
    },
    container: {
        position: "relative",
        textAlign: "center",
        color: "white",
    },
    sosiska: {
        position: "absolute",
        top: "100%",
        left: "27%",
        paddingLeft: "10px",
        paddingRight: "10px",
        transform: "translate(-50%, -50%)",
    },
});


export default function Slider() {
    const styles = useStyles();
    return (
        <div className={styles.container} id={"main"}>
                <div className={styles.logo}  >
                    &nbsp;
                </div>
            <Carousel >
                <Carousel.Item interval={2000}>
                    <img src={firstSlide} alt=""  className={styles.slider} />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img className={styles.slider} src={secondSlide} alt="Second slide"/>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img className={styles.slider} src={thirdSlide} alt="Second slide"/>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img className={styles.slider} src={fourthSlide} alt="Second slide"/>
                </Carousel.Item>
            </Carousel>
            <img src={kelbasa} width={"7%"} alt="" className={styles.sosiska} id={"aboutUs"}/>
        </div>

    )
}





