import {Grid, makeStyles, Typography} from "@material-ui/core";
import number1 from "../media/aboutUs3-1Number.png";
import number2 from "../media/aboutUs3-2Number.png";
import number3 from "../media/aboutUs3-3Number.png";
import image1 from "../media/aboutUs3-1.png";
import image2 from "../media/aboutUs3-2.png";
import image3 from "../media/aboutUs3-3.png";
import line from "../media/line.png"

const useStyles = makeStyles((theme) => ({
    images: {
        objectFit: "cover",
        maxWidth: "70px",
        height: "auto",
        marginTop: "20px"

    },
    pigImage: {
        objectFit: "cover",
        maxWidth: "120px",
        height: "auto",
        marginTop: "20px"

    },
    numbers: {
        objectFit: "cover",
        maxWidth: "100px",
        height: "auto",
        ['@media (max-Width: 1099px)']: {
            display: "none"
        }
    },
    mainWords: {
        backgroundColor: "#2f3030",
        color: "white",
        padding: "5px",
        borderRadius: "50px",
        marginBottom: "20px",
        marginLeft: "35px",
        marginRight: "35px",
        fontFamily: 'Ubuntu',
        fontSize: "2.5vw",
        fontStyle: "normal",
        ['@media (max-Width: 1099px)']: {
            marginLeft: "15px",
            marginRight: "15px",
            fontSize: "3.5vw",
        }
    },
    AboutUs: {
        paddingLeft: "10%",
        paddingRight: "10%",
        paddingBottom: "3%",
    },
    categoryName: {
        marginTop: "20px",
        marginBottom: "20px",
        fontFamily: 'Ubuntu',
        fontSize: "1.5vw",
        ['@media (max-Width: 1099px)']: {
            fontSize: "3.5vw",
        }
    },
    AboutUsColumn: {
        marginTop: "20px",
        marginBottom: "20px",
        minWidth: "200px"
    },
    line: {
        marginBottom: "25%",
        maxWidth: "60px",
        ['@media (max-Width: 1099px)']: {
            display: "none"
        }
    },
    belowName: {
        fontFamily: 'Ubuntu',
        fontStyle: "normal",
        fontSize: "1.2vw",
        ['@media (max-Width: 1099px)']: {
            fontSize: "3.5vw",
            fontWeight: "bold"
        }
    }
}))

export const AboutUsThird = () => {
    const styles = useStyles()
    return (

        <Grid container direction={"column"} className={styles.AboutUs}>
            <Grid item xs>
                <Typography align={"center"} variant={"h3"} id={"whyWe"} className={styles.mainWords}>
                    <strong> ЧОМУ НАША ПРОДУКЦІЯ?</strong>
                </Typography>
            </Grid>
            <Grid container wrap={true}>
                <Grid item xs direction={"column"} className={styles.AboutUsColumn}>
                    <Typography align={"center"}>
                        <img src={number1} alt="" className={styles.numbers}/>
                    </Typography>
                    <div>
                        <Typography align={"center"}>
                            <img src={image1} alt="" className={styles.pigImage}/>
                        </Typography>
                        <Typography align={"center"} className={styles.categoryName}>
                            <strong>
                                ВИКЛЮЧНО <br/> ЯКІСНА СИРОВИНА
                            </strong>
                        </Typography>
                    </div>
                    <Typography align={"center"} className={styles.belowName}>
                        Вироби смачні та <br/>
                        безпечні для споживання.
                    </Typography>
                </Grid>
                <Grid container xs className={styles.line} justifyContent="center"
                      alignItems="center" alignContent={"center"}>
                    <img src={line}/>
                </Grid>
                <Grid item xs direction={"column"} className={styles.AboutUsColumn}>
                    <Typography align={"center"}>
                        <img src={number2} alt="" className={styles.numbers}/>
                    </Typography>
                    <Typography align={"center"}>
                        <img src={image2} alt="" className={styles.images}/>
                    </Typography>
                    <Typography align={"center"} className={styles.categoryName}>
                        <strong>
                            ТЕХНОЛОГІЯ <br/> ВИГОТОВЛЕННЯ
                        </strong>
                    </Typography>
                    <Typography align={"center"} className={styles.belowName}>
                        Весь процес виробництва -  <br/>
                        це новітні технології <br/>
                        з використанням давніх рецептур.
                    </Typography>
                </Grid>
                <Grid container xs className={styles.line} justifyContent="center"
                      alignItems="center" alignContent={"center"}>
                    <img src={line}/>
                </Grid>
                <Grid item xs direction={"column"} className={styles.AboutUsColumn}>
                    <Typography align={"center"}>
                        <img src={number3} alt="" className={styles.numbers}/>
                    </Typography>
                    <Typography align={"center"}>
                        <img src={image3} alt="" className={styles.images}/>
                    </Typography>
                    <Typography align={"center"} className={styles.categoryName}>
                        <strong>
                            ДОСТАВКА <br/> ПРОДУКЦІЇ
                        </strong>
                    </Typography>
                    <Typography align={"center"} className={styles.belowName}>
                        Наша транспортна служба <br/>
                        вчасно привозить продукцію <br/>
                        у місця продажу.
                    </Typography>
                </Grid>
            </Grid>

        </Grid>
    )
}