import {Grid, makeStyles, Typography} from "@material-ui/core";

import aboutUs from "../media/aboutUs.png"

const useStyles = makeStyles((theme) => ({
    AboutUs: {
        marginTop: "30px"
    },
    AboutUsImage: {
        objectFit: "cover",
        width: "100%",
        height: "auto",

    },
    container: {
        position: "relative",
        textAlign: "center",
        color: "white"
    },
    text: {
        position: "absolute",
        top: "100%",
        left: "50%",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "15px",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#2f3030",
        fontSize: "1.2vw",
        fontFamily: "Ubuntu",
        ['@media (max-Width: 550px)']: {
            fontSize: "2.8vw",
        }
    },
    AboutUsWords: {
        fontFamily: 'Ubuntu',
        marginLeft: "20%",
        fontSize: "3.125rem",
        ['@media (max-Width: 550px)']: {
            fontSize: "1rem"
        }
    },
    maintext: {
        fontFamily: 'Ubuntu',
        fontSize: "1.5rem",
        ['@media (max-Width: 550px)']: {
            fontSize: "1rem"
        }
    }
}))


export default function AboutUs() {
    const styles = useStyles()

    return (
        <Grid container direction={"column"} className={styles.AboutUs}>
            <Grid container direction={"column"}>
                <Typography  className={styles.AboutUsWords}><strong>ПРО НАС</strong></Typography>
                <Typography className={styles.maintext} align={"center"}>
                    Наше підприємство знаходиться у мальовничому куточку України. <br/>
                    У підніжжі гір Карпат, неподалік курортного міста Трускавець <br/>
                    та славетного міста Дрогобич!
                </Typography>
            </Grid>
            <Grid item xs className={styles.container}>
                <img className={styles.AboutUsImage} src={aboutUs} alt={"mountains"}/>
                <Typography className={styles.text}>
                    <strong>
                        РАНЕВИЦЬКІ КОВБАСИ
                    </strong>
                </Typography>
            </Grid>

        </Grid>

    )
}

