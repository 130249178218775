import {Card} from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import clock from "../../media/ProductCatalog/clock-circular-outline копія копия.png"
import vac from "../../media/ProductCatalog/Шар 17 копія копия.png"
import one from "../../media/ProductCatalog/1.png"
import two from "../../media/ProductCatalog/2.png"
import three from "../../media/ProductCatalog/3.png"
import React from "react";

const useStyles = makeStyles({
    root: {
        width: 260,
        marginBottom: "20px",
        borderRadius: "2px",
        fontFamily: 'Ubuntu',
    },
    media: {
        height: 200,
        width: "100%",
    },
    span: {
        padding: "3px",
        verticalAlign: "middle"
    }
});


export default function CategoryCard(props) {
    const classes = useStyles()
    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={props.data.productImage}
                    title="Contemplative Reptile"
                />
                <CardContent style={{padding: 0}}>
                    <Typography  align={"center"} style={{
                        padding: 0,
                        margin: 0,
                        fontFamily: "Ubuntu",
                        fontWeight: "bold",
                        color: "#595959",
                        fontSize: "14px"
                    }}>
                        {props.data.productName}
                    </Typography>
                    <hr style={{padding: 0, margin: 0, filter: "blur(1px)"}}/>
                    <Typography align={"center"} color="textSecondary" component="p"
                                style={{padding: "3px"}}>
                        <span className={classes.span}><img src={clock} alt="time"/> {props.data.expirationDate}</span>
                        {props.data.expirationDateInVacuum ?
                            <span className={classes.span}><img src={vac} alt="time"
                                                                style={{margin: "3px"}}/>{props.data.expirationDateInVacuum}</span> : ""}
                        {props.data.class === 1 ? <span className={classes.span}><img src={one} alt=""
                                                                                      style={{margin: "3px"}}/>гатунок</span> :
                            props.data.class === 2 ?
                                <span className={classes.span}><img src={two} style={{margin: "3px"}} alt="гатунок"/>гатунок</span> :
                                <span className={classes.span}><img src={three} style={{margin: "3px"}} alt="гатунок"/>гатунок</span>}
                    </Typography>
                    <hr style={{padding: 2, margin: 0, filter: "blur(1px)"}}/>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}