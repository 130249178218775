import BOILED_SAUSAGES_HEADER from "../media/ProductCatalog/BOILED_SAUSAGES/BOILED_SAUSAGES_HEADER.png";
import BOILED_SAUSAGES_CATEGORY from "../media/ProductCatalog/BOILED_SAUSAGES/BOILED_SAUSAGES_CATEGORY.png";
import one from "../media/ProductCatalog/BOILED_SAUSAGES/КОВБАСА 'МОЛОЧНА'.png"
import two from "../media/ProductCatalog/BOILED_SAUSAGES/КОВБАСА 'САЛАТНА'.png"
import three from "../media/ProductCatalog/BOILED_SAUSAGES/КОВБАСА 'МОРТАДЕЛА'.png"
import four from "../media/ProductCatalog/BOILED_SAUSAGES/КОВБАСА 'БУТЕРБРОДНА'.png"
import five from "../media/ProductCatalog/BOILED_SAUSAGES/КОВБАСА 'ЛІКАРСЬКА'.png"
import six from "../media/ProductCatalog/BOILED_SAUSAGES/КОВБАСА 'З МОЛОКОМ'.png"
import seven from "../media/ProductCatalog/BOILED_SAUSAGES/КОВБАСА 'СМАЧНА'.png"
import eight from "../media/ProductCatalog/BOILED_SAUSAGES/КОВБАСА 'ОЛІВ'Є'.png"
import nine from "../media/ProductCatalog/BOILED_SAUSAGES/КОВБАСА 'ШИНКОВА'.png"
import ten from "../media/ProductCatalog/BOILED_SAUSAGES/КОВБАСА 'ЛЮБИТЕЛЬСЬКА'.png"
import eleven from "../media/ProductCatalog/BOILED_SAUSAGES/ШИНКА 'БУТЕРБРОДНА'.png"
import twelve from "../media/ProductCatalog/BOILED_SAUSAGES/ШИНКА 'ТУРИСТ'.png"
import thirteen from "../media/ProductCatalog/BOILED_SAUSAGES/КУРКА 'ПРЕСОВАНА'.png"
import fourteen from "../media/ProductCatalog/BOILED_SAUSAGES/ШИНКА 'ПРЕСОВАНА'.png"

import SMOKED_SAUSAGES_CATEGORY from "../media/ProductCatalog/smoked_sausages/SMOKED_SAUSAGES_CATEGORY.png"
import SMOKED_SAUSAGES_HEADER from "../media/ProductCatalog/smoked_sausages/SMOKED_SAUSAGES_HEADER.png"
import sixteen from "../media/ProductCatalog/smoked_sausages/ГАЙДАМАЦЬКА.png"
import eighteen from "../media/ProductCatalog/smoked_sausages/ДОМАШНЯ МЕЛЕНА.png"
import nineteen from "../media/ProductCatalog/smoked_sausages/ВІДЕНСЬКА.png"
import twenty from "../media/ProductCatalog/smoked_sausages/ДРОГОБИЦЬКА.png"
import seventeen from "../media/ProductCatalog/smoked_sausages/ГУЦУЛЬСЬКА.png"
import twenty_one from "../media/ProductCatalog/smoked_sausages/КОВБАСКИ 'КАБАНОССІ'.png"
import twenty_two from "../media/ProductCatalog/smoked_sausages/КУЛИКІВСЬКА.png"
import twenty_three from "../media/ProductCatalog/smoked_sausages/ЛЬВІВСЬКА.png"
import twenty_four from "../media/ProductCatalog/smoked_sausages/СТРИЙСЬКА.png"
import twenty_five from "../media/ProductCatalog/smoked_sausages/СТАРОКИЇВСЬКА.png"
import twenty_six from "../media/ProductCatalog/smoked_sausages/НА ДРОВАХ.png"
import twenty_seven from "../media/ProductCatalog/smoked_sausages/ПАНСЬКА.png"
import twenty_eight from "../media/ProductCatalog/smoked_sausages/ДОМАШНЯ КУРЯЧА.png"
import twenty_nine from "../media/ProductCatalog/smoked_sausages/ПОПУЛЯРНА.png"
import thirty from "../media/ProductCatalog/smoked_sausages/РУБЛЕНА З КМИНОМ.png"
import thirty_one from "../media/ProductCatalog/smoked_sausages/САЛЯМІ ТІРІТОЛЬСЬКА.png"
import thirty_two from "../media/ProductCatalog/smoked_sausages/САЛЯМІ БАВАРСЬКА.png"
import forty_three from "../media/ProductCatalog/smoked_sausages/КОВБАСКИ ШАШЛИЧНІ.png"
import thirty_three from "../media/ProductCatalog/smoked_sausages/САЛЯМІ КЛАСІК.png"
import thirty_four from "../media/ProductCatalog/smoked_sausages/САЛЯМІ ОРИГІНАЛЬНА.png"
import thirty_five from "../media/ProductCatalog/smoked_sausages/СЕЛЯНСЬКА.png"
import thirty_six from "../media/ProductCatalog/smoked_sausages/СЕЛЯНСЬКА2.png"
import thirty_seven from "../media/ProductCatalog/smoked_sausages/ФЕРМЕРСЬКА.png"
import thirty_eight from "../media/ProductCatalog/smoked_sausages/ЧАСНИКОВА.png"
import thirty_nine from "../media/ProductCatalog/smoked_sausages/КОВБАСКИ 'ШАШЛИЧНІ'.png"
import forty from "../media/ProductCatalog/smoked_sausages/САЛЯМІ 'ЗОЛОТИСТА'.png"
import forty_one from "../media/ProductCatalog/smoked_sausages/САЛЯМІ 'ІТАЛІЙСЬКА'.png"
import forty_two from "../media/ProductCatalog/smoked_sausages/САЛЯМІ 'ТАТАРСЬКА'.png"
import forty_four from "../media/ProductCatalog/smoked_sausages/САЛЯМІ 'БАНКЕТНА'.png"

import SMOKED_AND_DELICACIES_CATEGORY from "../media/ProductCatalog/SMOKED AND DELICACIES/SMOKED AND DELICACIES.png"
import SMOKED_AND_DELICACIES_HEADER from "../media/ProductCatalog/SMOKED AND DELICACIES/SMOKED AND DELICACIES HEADER.png"
import forty_five from "../media/ProductCatalog/SMOKED AND DELICACIES/БАЛИК 'БЕНКЕТНИЙ'.png"
import forty_six from "../media/ProductCatalog/SMOKED AND DELICACIES/ГРУДИНКА 'МАРОЧНА'.png"
import forty_seven from "../media/ProductCatalog/SMOKED AND DELICACIES/ГРУДИНКА 'ОРИГІНАЛЬНА'.png"
import forty_nine from "../media/ProductCatalog/SMOKED AND DELICACIES/ОКІС 'БЕНКЕТНИЙ'.png"
import fifty from "../media/ProductCatalog/SMOKED AND DELICACIES/ОКІСТЯ 'ПО-СЕЛЯНСЬКИ'.png"
import fifty_one from "../media/ProductCatalog/SMOKED AND DELICACIES/ПОРОСЯ 'РІЗДВЯНЕ' 1кг.png"
import fifty_two from "../media/ProductCatalog/SMOKED AND DELICACIES/ПОРОСЯ 'РІЗДВЯНЕ' 0.5кг.png"
import fifty_three from "../media/ProductCatalog/SMOKED AND DELICACIES/РЕБРА 'ЄГЕРСЬКІ'.png"
import fifty_four from "../media/ProductCatalog/SMOKED AND DELICACIES/РЕБРА 'ЛЮКС'.png"
import fifty_five from "../media/ProductCatalog/SMOKED AND DELICACIES/РУЛЕТ 'АПЕТИТНИЙ'.png"
import fifty_six from "../media/ProductCatalog/SMOKED AND DELICACIES/РУЛЕТ 'АСОРТІ'.png"
import fifty_seven from "../media/ProductCatalog/SMOKED AND DELICACIES/РОЛЕТ 'СВИННИЙ КОПЧЕНИЙ'.png"
import fifty_eight from "../media/ProductCatalog/SMOKED AND DELICACIES/САЛО 'ЗАКУСОЧНЕ'.png"
import fifty_nine from "../media/ProductCatalog/SMOKED AND DELICACIES/САЛО 'ПО-ДОМАШНЬОМУ'.png"
import sixty from "../media/ProductCatalog/SMOKED AND DELICACIES/САЛО 'ПО-УГОРСЬКИ'.png"
import sixty_one from "../media/ProductCatalog/SMOKED AND DELICACIES/СВИНИНА 'ФОРМОВАНА'.png"
import sixty_two from "../media/ProductCatalog/SMOKED AND DELICACIES/ШИЙКА 'ПО-ПОЛТАВСЬКИ'.png"
import sixty_three from "../media/ProductCatalog/SMOKED AND DELICACIES/ШИЙКА 'АРОМАТНА'.png"
import sixty_four from "../media/ProductCatalog/SMOKED AND DELICACIES/ШИЙКА 'МИСЛИВСЬКА'.png"
import sixty_five from "../media/ProductCatalog/SMOKED AND DELICACIES/БЕДРО 'КУРЯЧЕ'.png"
import sixty_six from "../media/ProductCatalog/SMOKED AND DELICACIES/ГОМІЛКИ 'КУРЯЧІ'.png"
import sixty_seven from "../media/ProductCatalog/SMOKED AND DELICACIES/КРИЛЬЦЯ 'АПЕТИТНІ'.png"
import sixty_eight from "../media/ProductCatalog/SMOKED AND DELICACIES/РУЛЕТ 'НІЖНИЙ'.png"
import sixty_nine from "../media/ProductCatalog/SMOKED AND DELICACIES/РУЛЕТ 'ОРИГІНАЛЬНИЙ'.png"
import seventy from "../media/ProductCatalog/SMOKED AND DELICACIES/СТЕГЕНЦЯ 'ДОМАШНІ'.png"
import seventy_one from "../media/ProductCatalog/SMOKED AND DELICACIES/КОПЧЕНЕ 'КУРЯЧЕ ФІЛЕ'.png"

import salcesons_and_pates_CATEGORY from "../media/ProductCatalog/salcesons and pates/salcesons_and_pates_CATEGORY.png"
import salcesons_and_pates_HEADER from "../media/ProductCatalog/salcesons and pates/salcesons_and_pates_HEADER.png"
import seventy_two from "../media/ProductCatalog/salcesons and pates/КИШКА З БУЛКИ.png"
import seventy_three from "../media/ProductCatalog/salcesons and pates/КОВБАСА КРОВЯНКА 'СМАЧНА'.png"
import seventy_four from "../media/ProductCatalog/salcesons and pates/ЛІВЕРНА 'ЗАКУСОЧНА'.png"
import seventy_five from "../media/ProductCatalog/salcesons and pates/ПАШТЕТ 'БУТЕРБРОДНИЙ'.png"
import seventy_six from "../media/ProductCatalog/salcesons and pates/ПАШТЕТ 'ЗП'ЄЦА'.png"
import seventy_seven from "../media/ProductCatalog/salcesons and pates/САЛЬТИСОН 'ЯЗИКОВИЙ'.png"
import seventy_eight from "../media/ProductCatalog/salcesons and pates/СМАЛЕЦЬ 'КЛАСИЧНИЙ'.png"

import SAUSAGES_SARDS_CATEGORY from "../media/ProductCatalog/SAUSAGES SARDS/SAUSAGES_SARDS.png"
import SAUSAGES_SARDS_HEADER from "../media/ProductCatalog/SAUSAGES SARDS/SAUSAGES SARDS HEADER.png"
import seventy_nine from "../media/ProductCatalog/SAUSAGES SARDS/САРДЕЛЬКИ 'З СИРОМ'.png"
import eighty from "../media/ProductCatalog/SAUSAGES SARDS/САРДЕЛЬКИ 'ТОВСТУНЧИКИ'.png"
import eighty_one from "../media/ProductCatalog/SAUSAGES SARDS/САРДЕЛЬКИ 'СТОЛОВІ'.png"
import eighty_two from "../media/ProductCatalog/SAUSAGES SARDS/САРДЕЛЬКИ 'АРОМАТНІ'.png"
import eighty_three from "../media/ProductCatalog/SAUSAGES SARDS/САРДЕЛЬКИ 'МОЛОЧНІ'.png"
import eighty_four from "../media/ProductCatalog/SAUSAGES SARDS/СОСИСКИ 'ЕСТОНСЬКІ'.png"
import eighty_five from "../media/ProductCatalog/SAUSAGES SARDS/СОСИСКИ 'З КУРКОЮ'.png"
import eighty_six from "../media/ProductCatalog/SAUSAGES SARDS/СОСИСКИ 'ФІЛЕЙНІ'.png"
import eighty_seven from "../media/ProductCatalog/SAUSAGES SARDS/САРДЕЛЬКИ 'АРОМАТНІ'2.png"
import eighty_nine from "../media/ProductCatalog/SAUSAGES SARDS/СОСИСКИ 'НІЖНІ'.png"
import ninety from "../media/ProductCatalog/SAUSAGES SARDS/САРДЕЛЬКИ 'ДО СНІДАНКУ'.png"
import ninety_one from "../media/ProductCatalog/SAUSAGES SARDS/КОВБАСКИ 'ЗАЛЬЦБУРГСЬКІ'.png"


import TRADITIONAL_SMOKING_CATEGORY from "../media/ProductCatalog/TRADITIONAL SMOKING/TRADITIONAL_SMOKING_CATEGORY.png"
import TRADITIONAL_SMOKING_HEADER from "../media/ProductCatalog/TRADITIONAL SMOKING/TRADITIONAL_SMOKING_HEADER.png"
import ninety_one1 from "../media/ProductCatalog/TRADITIONAL SMOKING/ГРУДИНКА 'ДОМАШНЯ'.png"
import ninety_two from "../media/ProductCatalog/TRADITIONAL SMOKING/БУЖЕНИНА 'З ПРЯНОЩАМИ'.png"
import ninety_three from "../media/ProductCatalog/TRADITIONAL SMOKING/ШИНКА 'ДОМАШНЯ'.png"
import ninety_four from "../media/ProductCatalog/TRADITIONAL SMOKING/БУЖЕНИНА 'ПО-СЕЛЯНСЬКИ'.png"
import ninety_five from "../media/ProductCatalog/TRADITIONAL SMOKING/ГОЛЯНКА 'ПО-ПОЛЬСЬКИ'.png"
import ninety_six from "../media/ProductCatalog/TRADITIONAL SMOKING/ГРУДИНКА 'З ЧАСНИКОМ'.png"
import ninety_seven from "../media/ProductCatalog/TRADITIONAL SMOKING/ГРУДИНКА 'ПР'ЯНА'.png"
import ninety_eight from "../media/ProductCatalog/TRADITIONAL SMOKING/ДОМАШНЯ ПЕЧЕНА.png"
import ninety_nine from "../media/ProductCatalog/TRADITIONAL SMOKING/КАРБОНАРД 'МІСЬКИЙ'.png"
import hundered_one from "../media/ProductCatalog/TRADITIONAL SMOKING/КОВБАСКИ 'БАНДЕРІВСЬКІ'.png"
import hundered_two from "../media/ProductCatalog/TRADITIONAL SMOKING/ПОЛЯДВИЦЯ.png"
import hundered_three from "../media/ProductCatalog/TRADITIONAL SMOKING/РУЛЕТ СВИННИЙ ЗАПЕЧЕНИЙ.png"
import hundered_four from "../media/ProductCatalog/TRADITIONAL SMOKING/ХЛІБЕЦЬ 'АПЕТИТНИЙ'.png"
import hundered_five from "../media/ProductCatalog/TRADITIONAL SMOKING/ХЛІБЕЦЬ 'ДОМАШНІЙ'.png"
import hundered_six from "../media/ProductCatalog/TRADITIONAL SMOKING/ШИЙКА 'СМАЧНА'.png"

export let categories = [
    {
        "categoryImage": BOILED_SAUSAGES_CATEGORY,
        "headerImage": BOILED_SAUSAGES_HEADER,
        "nameTag": "BOILED_SAUSAGES",
        "ВАРЕНІ КОВБАСИ": [
            {
                productName: "КОВБАСА 'МОЛОЧНА'",
                productImage: one,
                expirationDate: "25 діб",
                expirationDateInVacuum: "",
                class: 3
            },
            {
                productName: "КОВБАСА 'САЛАТНА'",
                productImage: two,
                expirationDate: "15 діб",
                expirationDateInVacuum: "",
                class: 1
            },
            {
                productName: "КОВБАСА 'МОРТАДЕЛА'",
                productImage: three,
                expirationDate: "25 діб",
                expirationDateInVacuum: "",
                class: 1
            },
            {
                productName: "КОВБАСА 'БУТЕРБРОДНА'",
                productImage: four,
                expirationDate: "21 доба",
                expirationDateInVacuum: "",
                class: 2
            },
            {
                productName: "КОВБАСА 'ЛІКАРСЬКА'",
                productImage: five,
                expirationDate: "30 діб",
                expirationDateInVacuum: "",
                class: 3
            },
            {
                productName: "КОВБАСА ВАРЕНА 'З МОЛОКОМ'",
                productImage: six,
                expirationDate: "15 діб",
                expirationDateInVacuum: "",
                class: 1
            },
            {
                productName: "КОВБАСА 'СМАЧНА'",
                productImage: seven,
                expirationDate: "21 доба",
                expirationDateInVacuum: "",
                class: 3
            },
            {
                productName: "КОВБАСА 'ОЛІВ'Є'",
                productImage: eight,
                expirationDate: "20 діб",
                expirationDateInVacuum: "",
                class: 1
            }
            , {
                productName: "КОВБАСА 'ШИНКОВА'",
                productImage: nine,
                expirationDate: "15 діб",
                expirationDateInVacuum: "",
                class: 1
            },
            {
                productName: "КОВБАСА 'ЛЮБИТЕЛЬСЬКА'",
                productImage: ten,
                expirationDate: "30 діб",
                expirationDateInVacuum: "",
                class: 3
            },
            {
                productName: "ШИНКА 'БУТЕРБРОДНА'",
                productImage: eleven,
                expirationDate: "21 доба",
                expirationDateInVacuum: "",
                class: 1
            },
            {
                productName: "ШИНКА 'ТУРИСТ'",
                productImage: twelve,
                expirationDate: "21 доба",
                expirationDateInVacuum: "",
                class: 1
            },
            {
                productName: "ШИНКА З БІЛОГО М'ЯСА",
                productImage: thirteen,
                expirationDate: "10 діб",
                expirationDateInVacuum: "",
                class: 1
            },
            {
                productName: "ШИНКОВА ТОСТОВА",
                productImage: fourteen,
                expirationDate: "10 діб",
                expirationDateInVacuum: "",
                class: 1
            },
        ],
    },
    {
        "categoryImage": SMOKED_SAUSAGES_CATEGORY,
        "headerImage": SMOKED_SAUSAGES_HEADER,
        "nameTag": "SMOKED_SAUSAGES",
        "КОВБАСИ ПІВКОПЧЕНІ, ВАРЕНОКОПЧЕНІ, СИРОВ'ЯЛЕНІ": [
            {
                productName: "ГАЙДАМАЦЬКА",
                productImage: sixteen,
                expirationDate: "5 діб",
                expirationDateInVacuum: "15 діб",
                class: 1
            },
            {
                productName: "ГУЦУЛЬСЬКА",
                productImage: seventeen,
                expirationDate: "15 діб",
                expirationDateInVacuum: "20 діб",
                class: 1
            }, {
                productName: "ДОМАШНЯ МЕЛЕНА",
                productImage: eighteen,
                expirationDate: "20 діб",
                expirationDateInVacuum: "",
                class: 3
            }, {
                productName: "САЛЯМІ ВІДЕНСЬКА",
                productImage: nineteen,
                expirationDate: "10 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "ДРОГОБИЦЬКА",
                productImage: twenty,
                expirationDate: "25 діб",
                expirationDateInVacuum: "",
                class: 3
            }, {
                productName: "КОВБАСКИ 'КАБАНОССІ'",
                productImage: twenty_one,
                expirationDate: "15 діб",
                expirationDateInVacuum: "",
                class: 3
            }, {
                productName: "КУЛИКІВСЬКА",
                productImage: twenty_two,
                expirationDate: "10 діб",
                expirationDateInVacuum: "25 діб",
                class: 2
            }, {
                productName: "ЛЬВІВСЬКА",
                productImage: twenty_three,
                expirationDate: "35 діб",
                expirationDateInVacuum: "",
                class: 3
            }, {
                productName: "СТРИЙСЬКА",
                productImage: twenty_four,
                expirationDate: "25 діб",
                expirationDateInVacuum: "",
                class: 3
            }, {
                productName: "СТАРОКИЇВСЬКА",
                productImage: twenty_five,
                expirationDate: "30 діб",
                expirationDateInVacuum: "",
                class: 3
            }, {
                productName: "НА ДРОВАХ",
                productImage: twenty_six,
                expirationDate: "15 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "ПАНСЬКА",
                productImage: twenty_seven,
                expirationDate: "15 діб",
                expirationDateInVacuum: "",
                class: 1
            }, {
                productName: "ДОМАШНЯ КУРЯЧА",
                productImage: twenty_eight,
                expirationDate: "15 діб",
                expirationDateInVacuum: "25 діб",
                class: 1
            }, {
                productName: "ПОПУЛЯРНА",
                productImage: twenty_nine,
                expirationDate: "5 діб",
                expirationDateInVacuum: "15 діб",
                class: 1
            }, {
                productName: "РУБЛЕНА З КМИНОМ",
                productImage: thirty,
                expirationDate: "15 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "САЛЯМІ ТІРОЛЬСЬКА",
                productImage: thirty_one,
                expirationDate: "15 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "САЛЯМІ БАВАРСЬКА",
                productImage: thirty_two,
                expirationDate: "5 діб",
                expirationDateInVacuum: "15 діб",
                class: 3
            }, {
                productName: "КОВБАСКИ 'ШАШЛИЧНІ'",
                productImage: forty_three,
                expirationDate: "15 діб",
                expirationDateInVacuum: "25 діб",
                class: 1
            }, {
                productName: "САЛЯМІ КЛАСІК",
                productImage: thirty_three,
                expirationDate: "15 діб",
                expirationDateInVacuum: "",
                class: 3
            }, {
                productName: "САЛЯМІ ОРИГІНАЛЬНА",
                productImage: thirty_four,
                expirationDate: "5 діб",
                expirationDateInVacuum: "15 діб",
                class: 3
            }, {
                productName: "СЕЛЯНСЬКА",
                productImage: thirty_five,
                expirationDate: "15 діб",
                expirationDateInVacuum: "25 діб",
                class: 1
            }, {
                productName: "СЕЛЯНСЬКА",
                productImage: thirty_six,
                expirationDate: "15 діб",
                expirationDateInVacuum: "",
                class: 2
            }, {
                productName: "ФЕРМЕРСЬКА",
                productImage: thirty_seven,
                expirationDate: "15 діб",
                expirationDateInVacuum: "25 діб",
                class: 1
            }, {
                productName: "ЧАСНИКОВА",
                productImage: thirty_eight,
                expirationDate: "15 діб",
                expirationDateInVacuum: "25 діб",
                class: 2
            }, {
                productName: "ШИНКА КОРОЛІВСЬКА",
                productImage: thirty_nine,
                expirationDate: "15 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "САЛЯМІ 'БАНКЕТНА'",
                productImage: forty_four,
                expirationDate: "4 місяці",
                expirationDateInVacuum: "25 діб",
                class: 1
            }, {
                productName: "САЛЯМІ 'ЗОЛОТИСТА'",
                productImage: forty,
                expirationDate: "4 місяці",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "САЛЯМІ 'ІТАЛІЙСЬКА'",
                productImage: forty_one,
                expirationDate: "4 місяці",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "САЛЯМІ 'ТАТАРСЬКА'",
                productImage: forty_two,
                expirationDate: "4 місяці",
                expirationDateInVacuum: "25 діб",
                class: 1
            }
        ],
    },
    {
        "categoryImage": SMOKED_AND_DELICACIES_CATEGORY,
        "headerImage": SMOKED_AND_DELICACIES_HEADER,
        "nameTag": "SMOKED_AND_DELICACIES",
        "КОПЧЕНОСТІ ТА ДЕЛІКАТЕСИ": [
            {
                productName: "БАЛИК 'БАНКЕТНИЙ'",
                productImage: forty_five,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "ГРУДИНКА 'МАРОЧНА'",
                productImage: forty_six,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "ГРУДИНКА 'ОРИГІНАЛЬНА'",
                productImage: forty_seven,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            },  {
                productName: "ОКІСТ 'БАНКЕТНИЙ'",
                productImage: forty_nine,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            }, {
                productName: "ОКІСТ 'ПО-СЕЛЯНСЬКИ'",
                productImage: fifty,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "ПОРОСЯ 'РІЗДВЯНЕ' 1кг",
                productImage: fifty_one,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "ПОРОСЯ РІЗДВЯНЕ' 0.5кг",
                productImage: fifty_two,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            }, {
                productName: "РЕБРА 'ЄГЕРСЬКІ'",
                productImage: fifty_three,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "РЕБРА 'ЛЮКС'",
                productImage: fifty_four,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "РУЛЕТ 'АПЕТИТНИЙ'",
                productImage: fifty_five,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            }, {
                productName: "РУЛЕТ 'АСОРТІ'",
                productImage: fifty_six,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "РУЛЕТ 'КОПЧЕНИЙ'",
                productImage: fifty_seven,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            }, {
                productName: "САЛО 'ЗАКУСОЧНЕ'",
                productImage: fifty_eight,
                expirationDate: "20 діб",
                expirationDateInVacuum: "",
                class: 1
            }, {
                productName: "САЛО 'ПО-ДОМАШНЬОМУ'",
                productImage: fifty_nine,
                expirationDate: "15 діб",
                expirationDateInVacuum: "",
                class: 1
            }, {
                productName: "САЛО 'ПО-УГОРСЬКИ'",
                productImage: sixty,
                expirationDate: "20 діб",
                expirationDateInVacuum: "",
                class: 1
            },{
                productName: "СВИНИНА 'ФОРМОВАНА'",
                productImage: sixty_one,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            },{
                productName: "ШИЙКА 'ПО-ПОЛТАВСЬКИ'",
                productImage: sixty_two,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            },{
                productName: "ШИНКА 'АРОМАТНА'",
                productImage: sixty_three,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            },{
                productName: "ШИНКА 'МИСЛИВСЬКА'",
                productImage: sixty_four,
                expirationDate: "20 діб",
                expirationDateInVacuum: "",
                class: 3
            },{
                productName: "БЕДРО 'КУРЯЧЕ'",
                productImage: sixty_five,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            },{
                productName: "ГОМІЛКИ 'КУРЯЧІ'",
                productImage: sixty_six,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            },{
                productName: "КРИЛЬЦЯ 'АПЕТИТНІ'",
                productImage: sixty_seven,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            },{
                productName: "РУЛЕТ 'НІЖНИЙ'",
                productImage: sixty_eight,
                expirationDate: "72 год",
                expirationDateInVacuum: "16 діб",
                class: 3
            },{
                productName: "РУЛЕТ 'ОРИГІНАЛЬНИЙ'",
                productImage: sixty_nine,
                expirationDate: "5 діб",
                expirationDateInVacuum: "16 діб",
                class: 3
            },{
                productName: "СТЕГЕНЦЯ 'ДОМАШНІ'",
                productImage: seventy,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            },{

                productName: "КОПЧЕНЕ 'КУРЯЧЕ ФІЛЕ'",
                productImage: seventy_one,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            },
        ],
    },
    {
        "categoryImage": salcesons_and_pates_CATEGORY,
        "headerImage": salcesons_and_pates_HEADER,
        "nameTag": "salcesons_and_pates",
        "САЛЬЦЕСОНИ ТА ПАШТЕТИ": [
            {
                productName: "КИШКА З БУЛКИ",
                productImage: seventy_two,
                expirationDate: "4 доби",
                expirationDateInVacuum: "10 діб",
                class: 1
            },{
                productName: "КОВБАСА КРОВ'ЯНА 'СМАЧНА'",
                productImage: seventy_three,
                expirationDate: "4 доби",
                expirationDateInVacuum: "10 діб",
                class: 1
            },{
                productName: "ЛІВЕРНА 'ЗАКУСОЧНА'",
                productImage: seventy_four,
                expirationDate: "4 доби",
                expirationDateInVacuum: "10 діб",
                class: 2
            },{
                productName: "ПАШТЕТ 'БУТЕРБРОДНИЙ'",
                productImage: seventy_five,
                expirationDate: "8 діб",
                expirationDateInVacuum: "",
                class: 1
            },{
                productName: "ПАШТЕТ 'З П'ЄЦА'",
                productImage: seventy_six,
                expirationDate: "48 год",
                expirationDateInVacuum: "15 діб",
                class: 1
            },{
                productName: "САЛЬТИСОН 'ЯЗИКОВИЙ'",
                productImage: seventy_seven,
                expirationDate: "20 діб",
                expirationDateInVacuum: "",
                class: 1
            },{
                productName: "СМАЛЕЦЬ М'ЯСНИЙ 'КЛАСИЧНИЙ'",
                productImage: seventy_eight,
                expirationDate: "20 діб",
                expirationDateInVacuum: "",
                class: 1
            }
        ],
    },
    {
        "categoryImage": SAUSAGES_SARDS_CATEGORY,
        "headerImage": SAUSAGES_SARDS_HEADER,
        "nameTag": "SAUSAGES_SARDS",
        "СОСИСКИ ТА САРДЕЛЬКИ": [
            {
                productName: "САРДЕЛЬКИ 'ТОВСТУНЧИК'",
                productImage: eighty,
                expirationDate: "48 год",
                expirationDateInVacuum: "20 діб",
                class: 1
            },{
                productName: "САРДЕЛЬКИ ЛІОНСЬКІ З СИРОМ",
                productImage: seventy_nine,
                expirationDate: "48 год",
                expirationDateInVacuum: "10 діб",
                class: 3
            },{
                productName: "САРДЕЛЬКИ 'СТОЛОВІ'",
                productImage: eighty_one,
                expirationDate: "48 год",
                expirationDateInVacuum: "20 діб",
                class: 1
            },{
                productName: "САРДЕЛЬКИ 'АРОМАТНІ'",
                productImage: eighty_two,
                expirationDate: "72 год",
                expirationDateInVacuum: "20 діб",
                class: 1
            },{
                productName: "САРДЕЛЬКИ 'МОЛОЧНІ'",
                productImage: eighty_three,
                expirationDate: "48 год",
                expirationDateInVacuum: "12 діб",
                class: 3
            },{
                productName: "СОСИСКИ 'ЕСТОНСЬКІ'",
                productImage: eighty_four,
                expirationDate: "10 діб",
                expirationDateInVacuum: "20 діб",
                class: 1
            },{
                productName: "СОСИСКИ 'З КУРКОЮ'",
                productImage: eighty_five,
                expirationDate: "10 діб",
                expirationDateInVacuum: "20 діб",
                class: 1
            },{
                productName: "СОСИСКИ 'МАЛЯТКО'",
                productImage: eighty_six,
                expirationDate: "15 год",
                expirationDateInVacuum: "20 діб",
                class: 3
            },{
                productName: "СОСИСКИ 'ФІЛЕЙНІ'",
                productImage: eighty_seven,
                expirationDate: "48 год",
                expirationDateInVacuum: "20 діб",
                class: 1
            },{
                productName: "СОСИСКИ 'НІЖНІ'",
                productImage: eighty_nine,
                expirationDate: "15 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            },{
                productName: "САРДЕЛЬКИ 'ДО СНІДАНКУ'",
                productImage: ninety,
                expirationDate: "72 год",
                expirationDateInVacuum: "20 діб",
                class: 1
            },{
                productName: "КОВБАСКИ 'ЗАЛЬЦБУРГСЬКІ'",
                productImage: ninety_one,
                expirationDate: "4 доби",
                expirationDateInVacuum: "20 діб",
                class: 3
            },
        ],
    },
    {
        "categoryImage": TRADITIONAL_SMOKING_CATEGORY,
        "headerImage": TRADITIONAL_SMOKING_HEADER,
        "nameTag": "TRADITIONAL_SMOKING",
        "ТРАДИЦІЙНЕ КОПЧЕННЯ": [
            {
                productName: "ГРУДИНКА 'ДОМАШНЯ'",
                productImage: ninety_one1,
                expirationDate: "10 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            },{
                productName: "БУЖЕНИНА 'З ПРЯНОЩАМИ'",
                productImage: ninety_two,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            },{
                productName: "ШИНКА 'ДОМАШНЯ'",
                productImage: ninety_three,
                expirationDate: "10 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            },{
                productName: "БУЖЕНИНА 'ПО-СЕЛЯНСЬКИ'",
                productImage: ninety_four,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            },{
                productName: "ГОЛЯНКА 'ПО-ПОЛЬСЬКИ'",
                productImage: ninety_five,
                expirationDate: "10 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            },{
                productName: "ГРУДИНКА 'З ЧАСНИКОМ'",
                productImage: ninety_six,
                expirationDate: "20 діб",
                expirationDateInVacuum: "",
                class: 3
            },{
                productName: "ГРУДИНКА 'ПРЯНА'",
                productImage: ninety_seven,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            },{
                productName: "ДОМАШНЯ ПЕЧЕНА",
                productImage: ninety_eight,
                expirationDate: "5 діб",
                expirationDateInVacuum: "15 діб",
                class: 1
            },{
                productName: "КАРБОНАРД 'МІСЬКИЙ'",
                productImage: ninety_nine,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            },{
                productName: "КОВБАСКИ 'БАНДЕРІВСЬКІ'",
                productImage: hundered_one,
                expirationDate: "15 діб",
                expirationDateInVacuum: "",
                class: 3
            },{
                productName: "ПОЛЯДВИЦЯ",
                productImage: hundered_two,
                expirationDate: "5 діб",
                expirationDateInVacuum: "25 діб",
                class: 3
            },{
                productName: "РУЛЕТ ЗАПЕЧЕНИЙ",
                productImage: hundered_three,
                expirationDate: "5 діб",
                expirationDateInVacuum: "20 діб",
                class: 3
            },{
                productName: "ХЛІБЕЦЬ 'АПЕТИТНИЙ'",
                productImage: hundered_four,
                expirationDate: "48 год",
                expirationDateInVacuum: "15 діб",
                class: 1
            },{
                productName: "ХЛІБЕЦЬ 'ДОМАШНІЙ'",
                productImage: hundered_five,
                expirationDate: "48 год",
                expirationDateInVacuum: "20 діб",
                class: 1
            },{
                productName: "ШИЙКА 'СМАЧНА'",
                productImage: hundered_six,
                expirationDate: "20 діб",
                expirationDateInVacuum: "",
                class: 3
            },
        ],
    }
]