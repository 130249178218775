import {Box, Card, CardMedia, Grid, makeStyles, Typography} from "@material-ui/core";
import {Info, InfoTitle} from "@mui-treasury/components/info";
import {useGalaxyInfoStyles} from "@mui-treasury/styles/info/galaxy";
import {useCoverCardMediaStyles} from '@mui-treasury/styles/cardMedia/cover';
import {NavLink} from "react-router-dom";
import React from "react";


const useStyles = makeStyles((styles) => ({
    content: {
        position: 'absolute',
        bottom: "45%",
        width: "100%",
        textShadow: "1px 1px 2px black, 0 0 1em black",
        ['@media (max-Width: 550px)']: {
            bottom: "35%"
        }
    },
    card: {
        boxShadow: 'none',
        position: 'relative',
        height: "350px",
        width: "350px",
        ['@media (max-Width: 550px)']: {
            height: "250px",
            width: "250px",
        }
    },

}))

export const CategoryCard = (props) => {
    const styles = useStyles()
    const mediaStyles = useCoverCardMediaStyles({bgPosition: 'top'});
    return (
        <Grid item>
            <NavLink to={{
                pathname: `/${(props.data.nameTag)}`,
                state: props.data
            }}>
                <Card className={styles.card}>
                    <CardMedia
                        classes={mediaStyles}
                        image={props.data.categoryImage}/>
                    <Box className={styles.content}>
                        <Info useStyles={useGalaxyInfoStyles}>
                            <Typography align={"center"}>
                                <InfoTitle style={{
                                    fontFamily: 'Ubuntu',
                                    fontSize: "1.4em"
                                }}>{Object.keys(props.data)[3]}</InfoTitle>
                            </Typography>
                        </Info>
                    </Box>
                </Card>
            </NavLink>
        </Grid>
    )
}