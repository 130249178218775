import {Grid, makeStyles, Typography} from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
    footer: {
        color: "white",
        backgroundColor: "#2f3030",
    },
    words: {
        margin: "7px"
    }
}))

function Footer() {

    const styles = useStyles()

    return (
        <Grid container direction={"column"} className={styles.footer}>
            <Typography align={"center"} className={styles.words}>
                All rights reserved ©
            </Typography>
        </Grid>
    )
}

export default Footer