import {Container, Grid, makeStyles, Typography} from "@material-ui/core";
import home from "../media/icons_home.png"
import mobile from "../media/icons_mobilephone.png"
import mail from "../media/mail.png"
import logo from "../media/logo.png"
import instagram from "../media/icons_instagram.png"
import viber from "../media/icons_viber.png"
import facebook from "../media/icons_facebook.png"


const useStyles = makeStyles(() => ({
    contacts: {
        backgroundColor: "#2c2c2c",
        color: "white",
        paddingBottom: "20px",
        "& a": {
            textDecoration: "none",
            color: "white"
        }

    },
    ContactsImage: {
        objectFit: "cover",
        maxWidth: "35px",
        maxHeight: "35px",
        margin: "5px"
    },
    ContactsIcons: {
        maxWidth: "25px",
        maxHeight: "25px",
        margin: "5px",
        ['@media (max-Width: 1099px)']: {
            display: "none"
        }
    },
    logo: {
        objectFit: "cover",
        ['@media (max-Width: 1099px)']: {
            width: '100px',
        }
    },
    upperName: {
        paddingTop: "15px",
        fontFamily: 'Ubuntu',
        fontSize: "2.5vw",
        ['@media (max-Width: 1099px)']: {
            fontSize: "4vw",
        }
    },
    names: {
        fontSize: "1.1vw",
        fontFamily: 'Ubuntu',
        ['@media (max-Width: 1099px)']: {
            fontSize: "3vw",
        }
    },

}))

export const Contacts = () => {
    const styles = useStyles()
    return (
        <div className={styles.contacts} id={"contacts"}>
            <Container maxWidth="lg">
                <Grid item xs>
                    <Typography align={"center"} variant={"h4"} className={styles.upperName}>
                        <strong>
                            КОНТАКТИ
                        </strong>
                    </Typography>
                </Grid>
                <hr style={{borderTop: "1px solid white", opacity: ".3"}}/>
                <Grid container direction={"row"} justifyContent="center" alignItems="center">
                    <Grid container item xs direction={"row"} justifyContent="center" alignItems="center">
                        <img src={logo} alt="logo" className={styles.logo} style={{margin: "5px"}}/>
                    </Grid>
                    <div style={{
                        borderLeft: "1px solid white",
                        height: "70px",
                        left: "50%",
                        margined: "-3px",
                        top: "0",
                        opacity: ".3"
                    }}/>
                    <Grid container item xs direction={"column"}>
                        <Grid item xs>
                            <Typography align={"center"} className={styles.names}>
                                <img src={home} alt="" className={styles.ContactsIcons}/> с. Раневичі, вул. Дрогобицька,
                                52
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography align={"center"} className={styles.names}>
                                <img src={mobile} alt="" className={styles.ContactsIcons}/> <a href="tel:+380674602554">067
                                46 02 554 </a> / <a href="tel:+380673444181">067 34 44 181</a>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography align={"center"} className={styles.names}>
                                <img src={mail} alt="" className={styles.ContactsIcons}/> <a
                                href="mailto:ran_kach1@ukr.net">ran_kach1@ukr.net</a>
                            </Typography>
                        </Grid>
                    </Grid>
                    <div style={{
                        borderLeft: "1px solid white",
                        height: "70px",
                        left: "50%",
                        margined: "-3px",
                        top: "0",
                        opacity: ".3"
                    }}/>
                    <Grid item xs>
                        <Typography align={"center"}>
                            <img src={instagram} alt="" className={styles.ContactsImage}/>
                            <img src={facebook} alt="" className={styles.ContactsImage}/>
                            <img src={viber} alt="" className={styles.ContactsImage}/>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}