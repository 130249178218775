import {Grid, makeStyles,Typography} from "@material-ui/core";
import AboutUsFourImage from "../media/AboutUsFour2.png";
import AboutUsThird from "../media/AboutUsThird.png";
import AboutUsSosiska from "../media/aboutUs3.png";


const useStyles = makeStyles((theme) => ({
    AboutUsImage: {
        objectFit: "cover",
        width: "100%",
        height: "auto",
    },
    AboutAsFour: {
        position: "relative",
        color: "white",
    },
    text: {
        position: "absolute",
        top: "75%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontFamily: 'Ubuntu',
        fontSize:"2.5vw",
    ['@media (max-Width: 1099px)']: {
        fontSize:"3.5vw",
    }

    },
    AboutUsSosiska: {
        maxHeight: "100px",
        position: "absolute",
        top: "15%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        ['@media (max-Width: 900px)']: {
            display: "none"
        }
    }
}))

export const AboutUsFour = () => {
    const styles = useStyles()

    return (
        <Grid container direction={"column"} id={"ourAchivements"}>
            <Grid item xs className={styles.AboutAsFour}>
                <Typography align={"center"}>
                    <img src={AboutUsSosiska} alt="" className={styles.AboutUsSosiska}/>
                </Typography>
                <img src={AboutUsThird} alt="" className={styles.AboutUsImage}/>
                <Typography align={"center"} noWrap variant={"h3"} className={styles.text}>
                    <strong>
                    МИ ГОРДІ СВОЇМИ ДОСЯГНЕННЯМИ!
                    </strong>
                </Typography>
            </Grid>
            <Grid item xs>
                <img src={AboutUsFourImage} alt="14% 45% 70%" className={styles.AboutUsImage}/>
            </Grid>
        </Grid>
    )
}