import Slider from "./Slide";
import Footer from "./Footer";
import AboutUs from "./AboutUs";
import {AboutUsSecond} from "./AboutUsSecond";
import {Container} from "@material-ui/core";
import {Map} from "./Map";
import {ProductionCatalog} from "./ProductionCatalog";
import {makeStyles} from "@material-ui/core/styles";
import {AboutUsThird} from "./AboutUsThird";
import {AboutUsFour} from "./AboutUsFour";
import {Contacts} from "./Contacts";
import {Header} from "./Header";
import {categories} from "../State/State";

const useStyles = makeStyles((theme) => ({
    Main: {
        padding: 0,
        maxWidth: "1580px"
    }
}))


function MainPage() {
    const classes = useStyles()
    return (
        <Container className={classes.Main}>
            <Header/>
            <Slider/>
            <AboutUs/>
            <AboutUsSecond/>
            <ProductionCatalog/>
            <AboutUsThird/>
            <AboutUsFour/>
            <Contacts/>
            <Map/>
            <Footer/>
        </Container>
    )
}

export default MainPage