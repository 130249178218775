import {Container, Grid, makeStyles, Typography} from "@material-ui/core";
import ProductPageFooter from "./ProductPageFooter";
import ProductCard from "./ProductCard";
import {useLocation} from "react-router-dom";
import {Header} from "../Header";
import {Map} from "../Map";
import Footer from "../Footer";


const useStyles = makeStyles((styles) => ({
    Content: {
        marginBottom: "20px",
        padding: "0em 10em 0em 10em",
        ['@media (max-Width: 1000px)']: {
            marginBottom: "5px",
            padding: "0em 0em 0em 0em",
        }
    },
    AboutUsImage: {
        objectFit: "cover",
        width: "100%",
        height: "auto",

    },
    Page: {
        padding: 0,
        maxWidth: "1580px"
    },
}))


export const ProductsPage = (props) => {
    const classes = useStyles()
    window.scrollTo(0, 0)
    const pageNum = (Object.keys(props.data))

    function renderSwitch(data) {
        return data.map(item => <ProductCard data={item}/>)
    }

    return (
            <Container className={classes.Page}>
                <Header/>
                <Typography align={"center"}>
                    <img src={props.data.headerImage} alt="" className={classes.AboutUsImage}/>
                </Typography>
                <section className={classes.Content}>
                    <Grid container direction="row" justify={"space-evenly"}>
                        {renderSwitch(props.data[`${pageNum[3]}`])}
                    </Grid>
                </section>
                <ProductPageFooter/>
                <Map/>
                <Footer/>
            </Container>
    )
}

