import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useCoverCardMediaStyles} from '@mui-treasury/styles/cardMedia/cover';
import {CategoryCard} from "./ProductPageNew/CategoryCard";
import {categories} from "../State/State";


const useStyles = makeStyles(() => ({
    cards: {
        paddingBottom: "40px",
        paddingTop: "10px",
    },
    content: {
        position: 'absolute',
        zIndex: 2,
        bottom: 0,
    },
    mainWords: {
        backgroundColor: "#2f3030",
        color: "white",
        borderRadius: "50px",
        margin: "20px",
        paddingRight: "30px",
        paddingLeft: "30px",
        paddingBottom: "5px",
        paddingTop: "5px",
        fontFamily: 'Ubuntu',
        fontSize: "2.5vw",
        ['@media (max-Width: 550px)']: {
            fontSize: "3vw",
            fontWeight: "bold"
        }
    },
    main: {
        paddingLeft: "10%",
        paddingRight: "10%",
        fontFamily: 'Ubuntu'
    },
}));


export const ProductionCatalog = () => {
    const styles = useStyles()
    const mediaStyles = useCoverCardMediaStyles({bgPosition: 'top'});

    function renderSwitch(categories) {
        return categories.map(item => <CategoryCard data={item}/>)
    }

    return (
        <Grid id="sample-code" container direction={"column"} className={styles.main}>
            <Grid container item direction="row" justifyContent="center" alignItems="center">
                <Grid item xs>
                    <hr style={{borderTop: "2px solid #2f3030"}}/>
                </Grid>
                <Grid item xs>
                    <Typography align={"center"} noWrap variant={"h3"} className={styles.mainWords}>
                        <strong> КАТАЛОГ ПРОДУКЦІЇ</strong>
                    </Typography>
                </Grid>
                <Grid item xs>
                    <hr style={{borderTop: "2px solid #2f3030"}}/>
                </Grid>
            </Grid>
            <Grid container={true} spacing={1} justifyContent={"center"} wrap={true} className={styles.cards}>
                {renderSwitch(categories)}
            </Grid>
        </Grid>
    )
}